import React, { useRef, useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import SlotCounter from "react-slot-counter";
import { SlotCounterRef } from "react-slot-counter";
import CountdownTimer from "@components/CountdownTimer";
import {
  RaffleDetailType,
  RaffleReward,
  RaffleStatus,
  UploadType,
} from "@type/Raffle";
import * as _ from "lodash";
import AlertPopup from "@components/AlertPopup";
import { WinResultType } from "@type/Raffle";
import { useNavigate } from "react-router-dom";
import defaultRaffleThumbnail from "@assets/img/raffle/defaultThumbnailRaffle.png";
import Img from "@assets/img";
import * as S from "./style";
import { Helmet } from "react-helmet-async";

export interface AlertType {
  header: string;
  title: string;
  description: string;
  icon: string;
  leftButton: {
    title: string;
    onClick: () => void;
    timer: number;
  };
  highLightContent: boolean;
}

interface Props {
  raffle: RaffleDetailType | undefined;
  reward: RaffleReward | undefined;
  win?: WinResultType | null;
  done: () => void;
  isCancel: boolean;
  listRef: any;
}

const RaffleInfo = ({
  raffle,
  reward,
  win,
  done,
  isCancel,
  listRef,
}: Props) => {
  const counterRef = useRef<SlotCounterRef>(null);
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertType>();
  const [cancel, setCancel] = useState<boolean>(isCancel);
  const navigate = useNavigate();
  const baseUrl = window.location.origin;
  const contentImageShare = raffle?.thumbnailImg
    ? raffle?.thumbnailImg
    : `${baseUrl}${defaultRaffleThumbnail}`;

  const contentForShow = () => {
    switch (raffle?.status) {
      case RaffleStatus.WAITING_PEOPLE:
        return (
          <div>
            {" "}
            Coming up in{" "}
            <CountdownTimer time={raffle?.startTime} isCancel={isCancel} />
          </div>
        );
      case RaffleStatus.HAPPENING:
        return (
          <div>
            Draw {raffle?.currentTurn} / {raffle?.numberOfTurns} in Progress
          </div>
        );
      case RaffleStatus.CANCEL:
        return <div>Raffle Cancellation</div>;
      case RaffleStatus.DONE:
        return <div>Raffle Done</div>;
      case RaffleStatus.ELIGIBLE:
        return (
          <div>
            {" "}
            Coming up in{" "}
            <CountdownTimer time={raffle?.startTime} isCancel={isCancel} />
          </div>
        );
    }
  };

  useEffect(() => {
    counterRef.current?.startAnimation({
      duration: 1,
      dummyCharacterCount: 50,
      direction: "top-down",
    });
  }, []);

  useEffect(() => {
    setCancel(isCancel);
  }, [isCancel]);

  useEffect(() => {
    if (win && win.win) {
      counterRef.current?.startAnimation({
        duration: 30,
        dummyCharacterCount: 500,
        direction: "top-down",
      });

      setTimeout(() => {
        const alertData = {
          header: "Round " + win.turn,
          title: "Lucky Number: " + win.win,
          description: win.reward,
          icon: Img.Gift,
          leftButton: {
            title: "Close",
            onClick: () => setIsAlert(false),
            timer: 5,
          },
          highLightContent: true,
        };

        setAlert(alertData);
        setIsAlert(true);
        done();
      }, 31000);
    }
  }, [win]);

  return (
    <>
      <Helmet>
        <meta property="og:title" content={raffle?.title} />
        <meta property="og:description" content="Raffle detail" />
        <meta property="og:image" content={contentImageShare} />
        <meta property="og:image:width" content="640" />
        <meta property="og:image:height" content="640" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={raffle?.title} />
        <meta name="twitter:description" content="Raffle detail" />
        <meta name="twitter:image" content={contentImageShare} />
      </Helmet>
      <S.Container>
        <S.SpinContainer $bgColor={raffle?.color?.background}>
          <Marquee autoFill>
            <S.MoveText $textColor={raffle?.color?.titleTurnNext}>
              <img src={Img.rocket} alt="rocket" />
              <span>Now Playing: {reward?.title}</span>
              <img src={Img.rocket} alt="rocket" />
              <span>Next Reward: {raffle?.nextRewardTitle}</span>
            </S.MoveText>
          </Marquee>
          <S.SpinContent
            $titleColor={raffle?.color?.title}
            $anotherColor={raffle?.color?.titleAnother}
          >
            <div className="turn-playing">{contentForShow()}</div>
            <p className="header-title">{reward?.title}</p>
            <div className="number-turn">
              Number of Draws: {raffle?.numberOfTurns}
            </div>
            <S.MachineWrapper>
              <S.LuckyNumber>
                <SlotCounter
                  value={
                    win?.win
                      ? _.padStart(win.win.toString(), 8, "0")
                      : "12345678"
                  }
                  autoAnimationStart={false}
                  ref={counterRef}
                  containerClassName="slot-counter"
                  charClassName="char"
                  animateUnchanged
                  startValue={"00000000"}
                  hasInfiniteList={true}
                />
              </S.LuckyNumber>
            </S.MachineWrapper>
            <div className="happen">
              <p className="happen-in">Next Draw in:</p>

              <div className="happen-time">
                {raffle && (
                  <CountdownTimer
                    time={raffle?.startTime}
                    isCancel={isCancel}
                  />
                )}
              </div>
            </div>
            {raffle?.status !== RaffleStatus.DONE && (
              <S.Button
                disabled={raffle?.status === RaffleStatus.HAPPENING}
                onClick={() => {
                  listRef?.current?.handleAddLuckyNumber();
                }}
              >
                Join Raffle For Free
              </S.Button>
            )}
          </S.SpinContent>
        </S.SpinContainer>
        <S.DetailContainer
          $bgColor={raffle?.color?.background}
          $anotherColor={raffle?.color?.titleAnother}
        >
          <div className="header">{reward?.title}</div>
          <div className="content">{reward?.detail}</div>
          <S.Hr />
          {reward?.metaData?.video?.url ? (
            reward?.metaData?.video?.type === UploadType.UPLOAD ? (
              <S.Video autoPlay src={reward.metaData.video.url} />
            ) : (
              <S.IFrame
                frameBorder="0"
                src={reward.metaData.video.url}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Video Player"
              />
            )
          ) : null}
          <div className="content">{reward?.description}</div>
          <div style={{ width: "100%" }}>
            <S.ImgBox bg={reward?.metaData?.img?.url} />
          </div>
        </S.DetailContainer>
        <AlertPopup
          isOpen={isAlert}
          header={alert?.header}
          title={alert?.title}
          description={alert?.description}
          icon={alert?.icon}
          leftButton={alert?.leftButton}
          highLightContent={alert?.highLightContent}
          onDismiss={() => setIsAlert(false)}
        />
        <AlertPopup
          isOpen={cancel}
          header="Raffle Cancellation"
          title="Insufficient participants! The raffle has been cancelled"
          icon={Img.RaffleCancel}
          leftButton={{
            title: "Join Other Raffles",
            onClick: () => navigate("/dashboard"),
          }}
          onDismiss={() => setCancel(false)}
        />
      </S.Container>
    </>
  );
};

export default RaffleInfo;
