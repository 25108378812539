import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Navigate, Outlet, useRoutes } from "react-router-dom";

import { RootState, persistor, store } from "@store/store";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// styles
import "bootstrap/scss/bootstrap.scss";
import "@assets/css/paper-kit.css?v=1.0.0";
import "@assets/demo/demo.css?v=1.0.0";
import "react-nice-scroll/dist/styles.css";
import "@assets/css/style.css?v=1.1.0";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

// pages
import Exam from "@components/Demo/Exam";
import Icons from "@components/Demo/Icons";
import Dashboard from "@views/Pages/Dashboard/Dashboard";
import RaffleDetail from "@views/Pages/Dashboard/RaffleDetail";
import Landing from "@views/Pages/Landing";
import FavoriteRaffle from "@views/Pages/Profile/FavoriteRaffle";
import ProfileDetails from "@views/Pages/Profile/ProfileDetails";
import ProfileTicket from "@views/Pages/Profile/ProfileTicket";

import Login from "@views/Pages/Login";
// others
import Widget from "@components/widget";
import { routes } from "@const/routes";
import { RaffleResult } from "@views/Pages/Dashboard/RaffleResult";
import { RaffleResultDetail } from "@views/Pages/Dashboard/RaffleResultDetail";
import GlobalStyle from "./Global";
import { DashboardLayout } from "./Layouts/DashboardLayout/DashboardLayout";
import useSession from "./hooks/useSession";
import VerifiedEmail from "@views/Pages/Verify";
import Gift from "@views/Pages/Gift";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const App: React.FC = () => {
  const notice = useSelector((state: RootState) => state.notice);

  useEffect(() => {
    switch (notice.type) {
      case "SUCCESS":
        toast.success(notice.content);

        break;
      case "ERROR":
        toast.error(notice.content);

        break;
      case "WAITING":
        toast.warning(notice.content);

        break;
      default:
        break;
    }
  }, [notice]);

  return (
    <>
      <HelmetProvider>
        <BrowserRouter>
          <GlobalStyle />
          <RouterList />
        </BrowserRouter>
        <ToastContainer />
      </HelmetProvider>
    </>
  );
};

const RouterList = () => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.token);

  useSession({ isLoggedIn: !!isLoggedIn });

  const PrivateRoutes = () => {
    return isLoggedIn ? <Outlet /> : <Navigate to={routes.login} replace />;
  };

  return useRoutes([
    { path: routes.landing, element: <Landing /> },
    { path: routes.verify, element: <VerifiedEmail /> },
    { path: routes.gift, element: <Gift /> },
    {
      element: <Login />,
      children: [{ path: routes.login }, { path: routes.referFriend }],
    },
    {
      element: <PrivateRoutes />,
      children: [
        {
          path: routes.ticket,
          element: (
            <ProfileTicket
              Available={""}
              Used={""}
              Registered={""}
              Expired={""}
            />
          ),
        },
        { path: routes.profile, element: <ProfileDetails /> },
        { path: routes.favoriteRaffle, element: <FavoriteRaffle /> },
      ],
    },
    { path: routes.favoriteRaffle, element: <FavoriteRaffle /> },
    {
      element: <DashboardLayout />,
      children: [
        {
          path: routes.dashboard,
          element: <Dashboard title="Dashboard" />,
        },
        {
          path: routes.raffleResult,
          element: <RaffleResult title="Completed Raffle Results" />,
        },
        {
          path: routes.raffleResultDetail,
          element: <RaffleResultDetail title="Raffle result" />,
        },
      ],
    },
    { path: routes.raffleDetail, element: <RaffleDetail /> },
    { path: routes.exam, element: <Exam /> },
    { path: routes.icons, element: <Icons /> },
    { path: routes.widget, element: <Widget /> },

    { path: "*", element: <Navigate to={routes.landing} replace /> },
  ]);
};

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
