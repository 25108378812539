import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import PopUp from "@components/PopUp";
import { clearStateAndPersist, RootState } from "@store/store";
import Img from "@assets/img";

import * as S from "./style";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { EAppAction } from "@store/appReducer";
import DashboardConstant from "@views/Pages/Dashboard/landing-image.constant";
import { Inbox } from "@novu/react";
import { jwtDecode } from "jwt-decode";
import AppearanceInbox from "../HeaderBar/inbox.style";
import * as Z from "../../components/PopUp/style";
import { PopUpContainer } from "@components/Navigate";

const applicationIdentifier =
  process.env.REACT_APP_NOVU_APPLICATION_IDENTIFIER || "";
const Navigate: React.FC = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: RootState) => state.auth.token);
  const navigate = useNavigate();
  const [isClick, setIsClick] = useState(false);
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [subscriberId, setSubscriberId] = useState("");
  const [subscriberIdHash, setSubscriberIdHash] = useState("");
  const decodedToken = () => {
    if (isLoggedIn) {
      const decoded: any = jwtDecode(isLoggedIn);
      setSubscriberId(decoded.subId);
      setSubscriberIdHash(decoded.subIdHash);
    }
  };

  const onOpenReferPopup = () => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }
    dispatch({
      type: EAppAction.SHOW_REFER_POPUP,
      payload: true,
    });
  };
  const handleNavigate = (tab: string) => {
    setIsClick(!isClick);
    navigate(tab);
  };

  const logout = () => {
    setVisiblePopup(false);
    clearStateAndPersist();
    navigate("/");
  };
  useEffect(() => {
    decodedToken();
  }, [isLoggedIn]);
  return (
    <S.Wrapper>
      <S.Container>
        <img
          src={DashboardConstant.WnnrLogo}
          alt="Logo"
          width={100}
          style={{ margin: "0px" }}
          height={"auto"}
        />
        <S.NavUserContainer>
          <S.UserNotification>
            {subscriberId && subscriberIdHash && (
              <Inbox
                applicationIdentifier={applicationIdentifier}
                subscriberId={subscriberId}
                subscriberHash={subscriberIdHash}
                appearance={AppearanceInbox.appearanceInbox}
              />
            )}
          </S.UserNotification>

          <S.Hamburger onClick={() => setIsClick(!isClick)}>
            <img
              src={Img.hamburgerLogo}
              alt="Logo"
              width={32}
              height={"auto"}
            />
          </S.Hamburger>
        </S.NavUserContainer>
      </S.Container>
      <S.NaviMoblie className={isClick ? "display" : "none"}>
        <S.Close onClick={() => setIsClick(!isClick)}>
          <img src={Img.closeLogo} alt="Logo" width={32} height={"auto"} />
        </S.Close>
        <S.Content>
          <h1>Raffle Participation</h1>
          <p onClick={() => handleNavigate("/dashboard")}>Raffle Lobby</p>
          <p onClick={() => handleNavigate("/raffle-result")}>Raffle Result</p>
          <p>Prize Redemption Guidance</p>
          <p onClick={() => handleNavigate("/profile")}>My Profile</p>
          <p onClick={() => handleNavigate("/ticket")}>My Ticket</p>
          <p onClick={() => handleNavigate("/favorite-raffle")}>
            My Favorite Raffle
          </p>
        </S.Content>
        <S.User>
          <p onClick={onOpenReferPopup}>Refer a friend</p>
          {isLoggedIn && <p onClick={() => setVisiblePopup(true)}>Log out</p>}
        </S.User>
      </S.NaviMoblie>
      <PopUp
        visible={visiblePopup}
        title="Gotta go?"
        footer={false}
        onOk={() => {
          setVisiblePopup(false);
          logout();
        }}
        onCancel={() => setVisiblePopup(false)}
        okText="No"
        cancelText="Yes"
      >
        <PopUpContainer>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <img src={Img.Mascot_1} alt="fire" width={180} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="popup-title">Log Out Session?</div>
              <Z.FooterModal>
                <Z.ButtonStyled
                  onClick={() => {
                    setVisiblePopup(false);
                    logout();
                  }}
                  style={{ marginRight: "15px", marginLeft: "10px" }}
                >
                  Yes
                </Z.ButtonStyled>
                <Z.ButtonCancel
                  onClick={() => {
                    setVisiblePopup(false);
                  }}
                >
                  No
                </Z.ButtonCancel>
              </Z.FooterModal>
            </div>
            <div></div>
          </div>
        </PopUpContainer>
      </PopUp>
    </S.Wrapper>
  );
};

export default Navigate;
