import { clearStateAndPersist } from "@store/store";
import axios, { AxiosError, AxiosResponse } from "axios";
import { set } from "lodash";
import { toast } from "react-toastify";

export enum HTTPStatusCodes {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },

  async (error: AxiosError<any>) => {
    const { response } = error;
    const statusCode = response?.status;
    const message = response?.data?.message;
    if (
      statusCode === HTTPStatusCodes.UNAUTHORIZED &&
      message === "Unauthorized"
    ) {
      clearStateAndPersist();
      toast.error("Token expired, please login again");
      setTimeout(() => {
        window.location.href = "/login";
      }, 2000);
    }
    if (statusCode === HTTPStatusCodes.FORBIDDEN) {
      window.location.href = "/not-permission";
    }

    return Promise.reject(response?.data);
  }
);
