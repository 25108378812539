import Img from "@assets/img";
import { Button } from "@components/Button";
import { VARIANTS } from "@components/Button/type";
import PopUp from "@components/PopUp";
import React, { useState } from "react";
import * as S from "./style";
import { Link } from "react-router-dom";
import classNames from "classnames";
import CONFIG from "src/config";

const ShareFriendPopup = ({
  isOpen,
  url,
  setIsOpen,
}: {
  isOpen: boolean;
  url: string;
  setIsOpen: () => void;
}) => {
  const [copied, setCopied] = useState(false);
  const ListSocial = [
    {
      img: Img.FacebookSolid,
      name: "Facebook",
      href: `${CONFIG.FACEBOOK_SHARE_LINK}${url}`,
    },
    {
      img: Img.XSolid,
      name: "X",
      href: `${CONFIG.X_SHARE_LINK}${url}`,
    },
    {
      img: Img.WhatsappSolid,
      name: "Whatsapp",
      href: `${CONFIG.WHATSAPP_SHARE_LINK}${url}`,
    },
    {
      img: Img.InstagramSolid,
      name: "Instagram",
      href: `${CONFIG.INSTAGRAM}`,
    },
  ];

  async function handleCopyLink() {
    if (navigator.clipboard) {
      setCopied(true);
      await navigator.clipboard.writeText(url);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onCloseReferPopup = () => {
    setIsOpen();
    setCopied(false);
  };

  return (
    <PopUp visible={isOpen} title="Share a raffle" onCancel={onCloseReferPopup}>
      <S.ModalContent>
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <div>
            <S.MascotImg src={Img.Mascot_1} />
          </div>
          <div>
            <S.List
              className={classNames(ListSocial.length <= 3 && "justify-around")}
            >
              {ListSocial.map((social) => {
                return (
                  <S.Item key={social.name}>
                    <Link to={social.href} target="_blank">
                      <img src={social.img} />
                      <p>{social.name}</p>
                    </Link>
                  </S.Item>
                );
              })}
            </S.List>
            <div className="refer-link">
              <p>{url}</p>
            </div>
            <div className="refer-footer">
              <Button
                variant={VARIANTS.OUTLINE}
                onClick={handleCopyLink}
                className="copy-button"
              >
                Copy
              </Button>
            </div>
            {copied && <p className="mt-2">Copied to clipboard.</p>}
          </div>
        </div>
      </S.ModalContent>
    </PopUp>
  );
};

export default ShareFriendPopup;
