import { RadioIcon } from "@components/Icons";
import React from "react";
import * as S from "./style";

type BaseRadioProps = React.ComponentPropsWithoutRef<"input"> & {
  label: React.ReactNode | string;
  className?: string;
};

const Radio = ({ label, checked, className, ...rest }: BaseRadioProps) => {
  return (
    <S.RadioContainer className={className}>
      <input type="radio" role="radio" className="hidden" {...rest} />
      <div>
        <RadioIcon checked={checked} />
      </div>
      {typeof label === "string" ? (
        <span
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
            color: "#fff",
            margin: "2px 0 0 2px",
          }}
          title={label}
        >
          {label}
        </span>
      ) : (
        label
      )}
    </S.RadioContainer>
  );
};

export default Radio;
