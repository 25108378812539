import Img from "@assets/img";
import { Button } from "@components/Button";
import { VARIANTS } from "@components/Button/type";
import PopUp from "@components/PopUp";
import { RootState } from "@store/store";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as S from "./style";
import { Link } from "react-router-dom";
import classNames from "classnames";
import CONFIG from "src/config";
import { useDispatch } from "react-redux";
import { EAppAction } from "@store/appReducer";

const ReferFriendPopup = ({ isOpen }: { isOpen: boolean }) => {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const appUrl = window.location.origin;
  const referLink = `${appUrl}/refer/${user.id}`;

  const ListSocial = [
    {
      img: Img.FacebookSolid,
      name: "Facebook",
      href: `${CONFIG.FACEBOOK_SHARE_LINK}${referLink}`,
    },
    {
      img: Img.XSolid,
      name: "X",
      href: `${CONFIG.X_SHARE_LINK}${referLink}`,
    },
    {
      img: Img.WhatsappSolid,
      name: "Whatsapp",
      href: `${CONFIG.WHATSAPP_SHARE_LINK}${referLink}`,
    },
    {
      img: Img.InstagramSolid,
      name: "Instagram",
      href: `${CONFIG.INSTAGRAM}`,
    },
  ];

  async function handleCopyLink() {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(referLink);
      setCopied(true);
    }
  }

  const onCloseReferPopup = () => {
    dispatch({
      type: EAppAction.SHOW_REFER_POPUP,
      payload: false,
    });
    setCopied(false);
  };

  return (
    <PopUp visible={isOpen} title="Refer a friend" onCancel={onCloseReferPopup}>
      <S.ModalContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            justifyContent: "center",
          }}
        >
          <div>
            <S.ImgMascot src={Img.Mascot_1} />
          </div>
          <div>
            <S.List
              className={classNames(ListSocial.length <= 3 && "justify-around")}
            >
              {ListSocial.map((social) => {
                return (
                  <S.Item key={social.name}>
                    <Link to={social.href} target="_blank">
                      <img src={social.img} />
                      <p>{social.name}</p>
                    </Link>
                  </S.Item>
                );
              })}
            </S.List>
            <div className="refer-link">
              <p>{referLink}</p>
            </div>
            <div className="refer-footer">
              <Button
                variant={VARIANTS.OUTLINE}
                onClick={handleCopyLink}
                className="copy-button"
              >
                Copy
              </Button>
            </div>
            {copied && <p className="mt-2">Copied to clipboard.</p>}
          </div>
        </div>
      </S.ModalContent>
    </PopUp>
  );
};

export default ReferFriendPopup;
