import Img from "@assets/img";
import PopUp, { SizeType } from "@components/PopUp";
import { QuestionPopup } from "@components/QuestionPopup";
import { TABLE_PARTICIPANT } from "@const/participant";
import { getLuckyNumbers, getParticipants, getRewards } from "@network/Raffle";
import {
  ListTableType,
  RaffleColorType,
  RaffleDetailType,
  RaffleStatus,
} from "@type/Raffle";
import { SCREEN } from "@type/index";
import * as _ from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import SelectNumber from "../SelectNumber";
import * as S from "./style";
import { GetAvailableTicket } from "@network/Dashboard";
import AlertPopup from "@components/AlertPopup";
import { useNavigate } from "react-router-dom";
import { routes } from "@const/routes";
import { EAppAction } from "@store/appReducer";
import { useDispatch, useSelector } from "react-redux";
import { updateNotice } from "@store/noticeReducer";
import { Errors } from "@const/error";
import { Tickets } from "@network/Profile/type";
import { RootState } from "@store/store";
import { timer } from "@utils/Helper";
import { Button } from "@components/Button";
import { VARIANTS } from "@components/Button/type";

enum ModalType {
  QUESTION = "QUESTION",
  EXHAUSTED_TICKET = "EXHAUSTED_TICKET",
  SELECT_NUMBER = "SELECT_NUMBER",
  MAX_PER_DAY = "MAX_PER_DAY",
  HAPPENING = "HAPPENING",
}

interface TicketApi {
  id: number;
  title: string;
  expiredDate: string;
}

interface TableItemProps {
  title: string;
  type: string;
  raffleId: number;
  mainRaffleId?: number;
  milestoneId?: number;
  answered: boolean;
  hide: boolean;
  raffle?: RaffleDetailType;
  onClickAction?: () => void;
  raffleType?: string;
  refetchRaffleDetail?: any;
  refresh?: number;
  availableTicketToday?: number;
  raffleStatus?: RaffleStatus;
  customColor?: RaffleColorType;
  time?: Date;
  isCancel?: boolean;
  shouldRefetchData: boolean;
  setShouldRefetchData: (data: boolean) => void;
}

export const headers: {
  [key: string]: string[];
  MY_LUCKY_NUMBER: string[];
  LIST_OF_PARTICIPANT: string[];
  REWARD_LIST: string[];
} = {
  MY_LUCKY_NUMBER: ["No", "Ticket Type", "Number"],
  LIST_OF_PARTICIPANT: ["No", "Name", "Number"],
  REWARD_LIST: ["No", "Reward", "Turn"],
};

const limit = 5;

const List = forwardRef<any, TableItemProps>(
  (
    {
      title,
      type,
      raffleId,
      mainRaffleId,
      hide,
      milestoneId,
      answered,
      raffleType,
      refresh,
      raffle,
      availableTicketToday,
      refetchRaffleDetail,
      raffleStatus,
      customColor,
      time,
      isCancel,
      shouldRefetchData,
      setShouldRefetchData,
    },
    ref
  ) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [list, setList] = useState<ListTableType[]>([]);
    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [header, setHeader] = useState<string[]>([]);
    const [openPopup, setOpenPopup] = useState<ModalType>();
    const [isAnswered, setIsAnswered] = useState<boolean>(false);
    const [tickets, setTickets] = useState<Tickets[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const isLoggedIn = useSelector((state: RootState) => state.auth.token);
    const [timeRemaining, setTimeRemaining] = useState("");

    const handleAddLuckyNumber = useCallback(async () => {
      try {
        // eslint-disable-next-line no-debugger
        if (!isLoggedIn) {
          return navigate("/login");
        }

        if (availableTicketToday !== undefined && availableTicketToday <= 0) {
          return setOpenPopup(ModalType.MAX_PER_DAY);
        }

        const isRequestAnswer = milestoneId && !isAnswered;
        if (isRequestAnswer) {
          return setOpenPopup(ModalType.QUESTION);
        }
        setLoading(true);

        const res = await GetAvailableTicket({ type: raffleType });
        setLoading(false);

        if (!res.data.tickets.length) {
          return setOpenPopup(ModalType.EXHAUSTED_TICKET);
        }
        if (res.status === "success") {
          const lists = res.data.tickets.map((ticket: TicketApi) => {
            return {
              id: ticket.id,
              value: ticket.id,
              label: ticket.title,
              expiredDate: ticket.expiredDate,
            };
          });

          setTickets(lists);
        }
        setOpenPopup(ModalType.SELECT_NUMBER);
      } catch (error) {
        console.log(error);
        updateNotice(
          {
            type: "ERROR",
            content: Errors.UNKNOWN,
          },
          dispatch
        );
      }
    }, [milestoneId, isAnswered]);

    const onClosePopup = () => {
      setOpenPopup(undefined);
    };

    const onFinishQuestionary = () => {
      setIsAnswered(true);
      setOpenPopup(ModalType.SELECT_NUMBER);
      handleAddLuckyNumber();
    };

    const onClickReferFriend = () => {
      onClosePopup();
      dispatch({
        type: EAppAction.SHOW_REFER_POPUP,
        payload: true,
      });
    };

    const fetch = async () => {
      if (isEnd || !raffleId) return;
      const param = {
        limit,
        offset: (page - 1) * limit,
        id: raffleId,
      };

      try {
        let resData;

        switch (type) {
          case TABLE_PARTICIPANT.MY_LUCKY_NUMBER:
            resData = await getLuckyNumbers({
              ...param,
              screen: SCREEN.VIEW_LUCKY_NUMBER,
            });

            break;
          case TABLE_PARTICIPANT.LIST_OF_PARTICIPANT:
            resData = await getParticipants(param);

            break;
          default:
            break;
        }

        if (resData.status === "success") {
          const listData = resData.data as ListTableType[];

          if (_.isArray(listData)) {
            setList((prev) => [...prev, ...listData]);
            setPage((prev) => prev + 1);
          }

          // if (_.size(listData) < limit) {
          //   setIsEnd(true);
          // }
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    useEffect(() => {
      if (answered) setIsAnswered(true);
    }, [answered]);

    const fetchReward = async () => {
      const param = {
        limit: 10,
        offset: 0,
        id: raffleId,
      };

      const resData = await getRewards(param);

      if (resData.status === "success") {
        const listData = resData.data as ListTableType[];
        setList(listData);
        setIsEnd(true);
      }
    };

    useEffect(() => {
      if (type === TABLE_PARTICIPANT.REWARD_LIST) {
        fetchReward();
      }
    }, [refresh]);

    useEffect(() => {
      fetch();
      setHeader(headers[type]);
    }, []);

    useEffect(() => {
      if (isCancel) return setTimeRemaining("Cancelled");
      if (!time) return;
      const interval = setInterval(() => {
        const currentTime = timer(time);
        setTimeRemaining(currentTime);
      }, 1000);

      return () => clearInterval(interval);
    }, [time]);

    useEffect(() => {
      if (timeRemaining === "Happening" && !list.length) {
        setOpenPopup(ModalType.HAPPENING);
      }
    }, [timeRemaining]);

    useImperativeHandle(ref, () => ({
      handleAddLuckyNumber: () => {
        handleAddLuckyNumber();
      },
    }));

    useEffect(() => {
      if (shouldRefetchData) {
        fetch();
      }
    }, [shouldRefetchData]);

    return (
      <S.Wrapper className={hide ? "hidden" : "active"}>
        <S.TableItem $bgColor={customColor?.background}>
          <S.TableTitle $titleColor={customColor?.titleHeading}>
            {title}
            {type === TABLE_PARTICIPANT.MY_LUCKY_NUMBER &&
              raffleStatus &&
              (raffleStatus === RaffleStatus.ELIGIBLE ||
                raffleStatus === RaffleStatus.WAITING_PEOPLE) && (
                <S.Addbtn
                  $titleColor={customColor?.titleHeading}
                  onClick={handleAddLuckyNumber}
                  disabled={isLoading || timeRemaining === "Happening"}
                >
                  <span>+</span>
                  <span>Add</span>
                </S.Addbtn>
              )}
          </S.TableTitle>
          <S.TableHeader $anotherColor={customColor?.titleAnother}>
            {header.map((item, index) => (
              <div className="header-item" key={index}>
                {item}
              </div>
            ))}
          </S.TableHeader>
          {_.size(list) > 0 ? (
            <S.TableBody id={`scrollableDiv-${type}`}>
              <InfiniteScroll
                dataLength={list.length}
                next={fetch}
                hasMore={true}
                loader={!isEnd ? <p style={{ padding: 10 }}>Loading...</p> : ""}
                scrollableTarget={`scrollableDiv-${type}`}
                scrollThreshold={0.6}
              >
                {list?.map((item, index) => {
                  return (
                    <S.TableBodyItem
                      key={index}
                      $anotherColor={customColor?.titleAnother}
                    >
                      <div className="body-item">{index + 1}</div>
                      <div className="body-item">{item.title}</div>
                      <div className="body-item">
                        {_.size(item.number) > 0 ? item.number.join(", ") : ""}
                        {item.winNumber ? ` - ${item.winNumber}` : ""}
                      </div>
                    </S.TableBodyItem>
                  );
                })}
              </InfiniteScroll>
            </S.TableBody>
          ) : (
            <S.NoSelection>
              <img src={Img.NoSelectionBg} />
            </S.NoSelection>
          )}
        </S.TableItem>
        {openPopup === ModalType.QUESTION && mainRaffleId && (
          <QuestionPopup
            refetchRaffleDetail={refetchRaffleDetail}
            onDismiss={onClosePopup}
            raffleId={mainRaffleId}
            milestoneId={milestoneId}
            raffleDetail={raffle}
            onFinish={onFinishQuestionary}
          />
        )}
        <PopUp
          visible={openPopup === ModalType.SELECT_NUMBER}
          onCancel={onClosePopup}
          title={"Select Lucky Number"}
        >
          <SelectNumber
            shouldRefetchData={shouldRefetchData}
            setShouldRefetchData={setShouldRefetchData}
            raffleId={raffleId}
            onClose={onClosePopup}
            tickets={tickets}
            availableTicketToday={availableTicketToday || 0}
          />
        </PopUp>
        <PopUp
          title="Oopps!"
          visible={openPopup === ModalType.EXHAUSTED_TICKET}
          onCancel={onClosePopup}
        >
          <S.PopUpContent>
            <S.JoinQuizWrapper>
              <S.JoinQuizImg src={Img.Mascot_2} />
              <div>
                <S.JoinQuizTitle>No Ticket? Earn One Now!</S.JoinQuizTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    marginTop: 10,
                  }}
                >
                  <Button
                    onClick={onClickReferFriend}
                    variant={VARIANTS.PRIMARY}
                    style={{
                      fontSize: "20px",
                      fontWeight: 500,
                      padding: "10px 12px",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  >
                    Refer a friend
                  </Button>
                  <Button
                    onClick={() => navigate(routes.profile)}
                    style={{
                      fontSize: "20px",
                      textWrap: "nowrap",
                      fontWeight: 500,
                      padding: "10px 12px",
                      color: "#fff",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  >
                    Complete profile
                  </Button>
                </div>
              </div>
            </S.JoinQuizWrapper>
          </S.PopUpContent>
        </PopUp>
        <AlertPopup
          isOpen={openPopup === ModalType.MAX_PER_DAY}
          header="Oopps!"
          title="You have reached a maximum limit of 3 tickets per day. Please do not select any more tickets"
          icon={Img.RaffleCancel}
          onDismiss={onClosePopup}
        />
        <AlertPopup
          isOpen={openPopup === ModalType.HAPPENING}
          header="Oopps!"
          title="This raffle has started, please join other raffles!"
          icon={Img.CreeperHead}
          onDismiss={onClosePopup}
          leftButton={{
            title: "Skip",
            onClick: onClosePopup,
          }}
          rightButton={{
            title: "Join Other Raffle",
            onClick: () => navigate(routes.dashboard),
          }}
        />
      </S.Wrapper>
    );
  }
);

export default List;
